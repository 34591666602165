import React, { useState, ChangeEvent } from 'react'
import { graphql } from 'gatsby'
import {
  AdStarBlock,
  Category,
  GeneralSettings,
  SponsoredCategories
} from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import {
  ORDERED_CATEGORIES,
  CATEGORIES,
  FINALIST_PASSWORD,
  WINNER_PASSWORD
} from '../../lib/data/Awards'
import assetsDataCapture from '../../api/assets'
import * as LeaderStyles from '../../components/Leader/Leader.module.scss'
import * as NominateStyles from '../../components/MuddyAwards/NominateWidget/NominateWidget.module.scss'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'

export enum AssetType {
  STORY = 'Story',
  SQUARE = 'SQ',
  POSTER = 'Poster',
  REGIONAL_SPONSOR = 'RegionalSponsored',
  REGIONAL_NONSPONSOR = 'RegionalNonSponsored'
}

export const generateLink = ({
  category,
  type
}: {
  category: string
  type: AssetType
}) => {
  const extension = 'jpg'

  return `https://dev.muddystilettos.co.uk/awards-2023/NationalFinalists/${type}/MS_MA23_NationalFinalist_${type}_${(
    '0' +
    (Number(category) + 1)
  ).slice(-2)}.${extension}`
}

const DownloadAsset = ({ url, type }: { url: string; type: string }) => {
  const openWindow = () => {
    if (typeof window !== undefined) {
      window.open(
        `https://dev.muddystilettos.co.uk/awards-2023/download.php?u=${url}&sz=d4o0we412ecsd0987afgFGzSa`,
        '',
        'resizable,height=500,width=500'
      )
    }
  }
  return (
    <button className={LeaderStyles.Button} onClick={openWindow}>
      Download
    </button>
  )
}

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      sponsoredCategories {
        slug
        title
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsAssetsProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
      sponsoredCategories: SponsoredCategories[]
    }
  }
}

interface IPersonalDetails {
  email?: string
  businessName?: string
  consent?: boolean
}

enum WhichAssets {
  FINALISTS = 'finalists',
  WINNERS = 'winners'
}

const AwardsAssets = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
      //   sponsoredCategories
    }
  }
}: AwardsAssetsProps) => {
  const [password, setPassword] = useState<string | null>(null)
  const [award, setAward] = useState<string | null>(null)
  const [personalDetails, setPersonalDetails] =
    useState<IPersonalDetails | null>(null)
  const [showAssets, setShowAssets] = useState<boolean | string>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [whichAssets, setWhichAssets] = useState<WhichAssets>()

  const checkInput = () => {
    return Boolean(
      (whichAssets === WhichAssets.FINALISTS ? award : true) &&
        personalDetails?.businessName &&
        personalDetails.email
    )
  }

  const sendData = async () => {
    setSending(true)
    try {
      if (
        personalDetails &&
        personalDetails.email &&
        personalDetails.businessName
      ) {
        const res = await assetsDataCapture(
          personalDetails?.email,
          personalDetails?.businessName,
          personalDetails.consent ?? false
        )
        if (
          res.data.status !== 'subscribed' &&
          res.data.status !== 'unsubscribed'
        ) {
          throw new Error(res.data.detail)
        } else {
          setShowAssets(true)
        }
      }
    } catch (e: any) {
      const error = e instanceof Error ? e.message : e
      setShowAssets(error)
    } finally {
      setSending(false)
    }
  }

  const handlePersonalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const thisOne = {
      [e.target.name]:
        e.target.name === 'consent' ? !personalDetails?.consent : e.target.value
    }
    const newObj = {
      ...personalDetails,
      ...thisOne
    }

    setPersonalDetails(newObj)
  }

  const handleSubmit = () => {
    if (checkInput()) {
      sendData()
    }
  }

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image: 'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      {/* <Leaderboard adSlot={'TopLB'} sticky /> */}
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ National Assets'}
            postURI={'/awards/national-assets'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'National Assets' }} />
          <p>
            Congratulations! All Winners and Finalists can enter their details
            to download FREE promotional assets using the password that has been
            emailed to them here.{' '}
          </p>

          <p>
            If you&apos;re using a mobile device, you will find the download in
            your &ldquo;files&rdquo; folder.
          </p>

          <p>
            Not received your password yet? Email{' '}
            <a href="mailto:hq@muddystilettos.co.uk">hq@muddystilettos.co.uk</a>{' '}
          </p>
        </Content>
      </div>
      <div>
        <Content>
          <div className={LeaderStyles.Assets}>
            <div
              className={`${NominateStyles.Wrapper} ${NominateStyles.NoBottomMargin}`}
            >
              <p>
                Choose whether you wish to download Finalist assets or Winner
                assets:
              </p>
              <div className={LeaderStyles.FinalistToggleButtons}>
                <button
                  className={`${
                    WhichAssets.FINALISTS === whichAssets &&
                    LeaderStyles.ActiveButton
                  } ${LeaderStyles.Button}`}
                  onClick={() => setWhichAssets(WhichAssets.FINALISTS)}
                >
                  Finalists
                </button>
                <button
                  className={`${
                    WhichAssets.WINNERS === whichAssets &&
                    LeaderStyles.ActiveButton
                  } ${LeaderStyles.Button}`}
                  onClick={() => setWhichAssets(WhichAssets.WINNERS)}
                >
                  Winners
                </button>
              </div>
              {whichAssets &&
                (whichAssets === WhichAssets.FINALISTS ||
                  whichAssets === WhichAssets.WINNERS) && (
                  <>
                    <div>
                      <p>
                        <label htmlFor={`email`}>
                          Enter your email address
                        </label>
                        <input
                          id="email"
                          type="text"
                          name="email"
                          defaultValue={''}
                          onChange={e => handlePersonalChange(e)}
                        />
                      </p>
                      <p>
                        <label htmlFor={`businessName`}>
                          Enter your business name
                        </label>
                        <input
                          id="businessName"
                          type="text"
                          name="businessName"
                          defaultValue={''}
                          onChange={e => handlePersonalChange(e)}
                        />
                      </p>
                      <p className={NominateStyles.CheckboxWrapper}>
                        <input
                          id="consent"
                          type="checkbox"
                          name="consent"
                          value={`consent`}
                          defaultChecked={false}
                          onChange={e => handlePersonalChange(e)}
                        />
                        <label htmlFor={`consent`}>
                          I&apos;d like to receive news about partnership
                          opportunities from Muddy Stilettos. For more
                          information please see our{' '}
                          <a
                            href={`https://muddystilettos.co.uk/awards/terms-and-conditions`}
                          >
                            Terms & Conditions
                          </a>
                          .
                        </label>
                      </p>
                    </div>
                    <p>
                      <label htmlFor="password">Enter the password</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        value={password || ''}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </p>
                    {password &&
                      password?.length > 8 &&
                      (whichAssets === WhichAssets.FINALISTS
                        ? password !== FINALIST_PASSWORD
                        : password !== WINNER_PASSWORD) && (
                        <p className={LeaderStyles.Error}>
                          Sorry, the password is incorrect
                        </p>
                      )}
                    {whichAssets === WhichAssets.FINALISTS && (
                      <>
                        <p>
                          <label htmlFor="category">Select the category</label>
                          <select
                            name="category"
                            id="category"
                            onChange={e => setAward(e.target.value)}
                            defaultValue={''}
                          >
                            <option value="" disabled></option>
                            {CATEGORIES.map((cat, index) => (
                              <option
                                key={index}
                                value={ORDERED_CATEGORIES.indexOf(cat)}
                              >
                                {cat}
                              </option>
                            ))}
                          </select>
                        </p>
                      </>
                    )}
                    <p>
                      <button
                        disabled={sending}
                        className={LeaderStyles.Button}
                        onClick={handleSubmit}
                      >{`${sending ? 'Loading' : 'Access My Assets'}`}</button>
                    </p>
                  </>
                )}
            </div>
          </div>
          <div className={LeaderStyles.Assets}>
            {password &&
              whichAssets === WhichAssets.FINALISTS &&
              password === FINALIST_PASSWORD &&
              showAssets &&
              showAssets === true && (
                <>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      {award && (
                        <a
                          href={generateLink({
                            category: award,
                            type: AssetType.SQUARE
                          })}
                          download
                        >
                          <img
                            src={generateLink({
                              category: award,
                              type: AssetType.SQUARE
                            })}
                            alt="Poster"
                          />
                        </a>
                      )}
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>National Finalist</strong> (Social Square)
                    </div>
                    {award && (
                      <DownloadAsset
                        url={generateLink({
                          category: award,
                          type: AssetType.SQUARE
                        })}
                        type={'mp4'}
                      />
                    )}
                  </div>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      {award && (
                        <a
                          href={generateLink({
                            category: award,
                            type: AssetType.STORY
                          })}
                          download
                        >
                          <img
                            src={generateLink({
                              category: award,
                              type: AssetType.STORY
                            })}
                            alt="Poster"
                          />
                        </a>
                      )}
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>National Finalist</strong> (Social Story)
                    </div>
                    {award && (
                      <DownloadAsset
                        url={generateLink({
                          category: award,
                          type: AssetType.STORY
                        })}
                        type={'mp4'}
                      />
                    )}
                  </div>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      {award && (
                        <a
                          href={generateLink({
                            category: award,
                            type: AssetType.POSTER
                          })}
                          download
                        >
                          <img
                            src={generateLink({
                              category: award,
                              type: AssetType.POSTER
                            })}
                            alt="Poster"
                          />
                        </a>
                      )}
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>National Finalist</strong> (Poster)
                    </div>
                    {award && (
                      <DownloadAsset
                        url={generateLink({
                          category: award,
                          type: AssetType.POSTER
                        })}
                        type={'jpg'}
                      />
                    )}
                  </div>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      {award && (
                        <a
                          href={`https://dev.muddystilettos.co.uk/awards-2023/NationalFinalists/Generic/MS_MA23_NationalFinalist_Generic_lg.png`}
                          download
                        >
                          <img
                            src={`https://dev.muddystilettos.co.uk/awards-2023/NationalFinalists/Generic/MS_MA23_NationalFinalist_Generic_lg.png`}
                            alt="Generic"
                          />
                        </a>
                      )}
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>Muddy Awards Finalist</strong> - Create Your Own
                      (1200 px)
                    </div>
                    {award && (
                      <DownloadAsset
                        url={generateLink({
                          category: award,
                          type: AssetType.POSTER
                        })}
                        type={'jpg'}
                      />
                    )}
                  </div>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      {award && (
                        <a
                          href={`https://dev.muddystilettos.co.uk/awards-2023/NationalFinalists/Generic/MS_MA23_NationalFinalist_Generic_sm.png`}
                          download
                        >
                          <img
                            src={`https://dev.muddystilettos.co.uk/awards-2023/NationalFinalists/Generic/MS_MA23_NationalFinalist_Generic_sm.png`}
                            alt="Generic"
                          />
                        </a>
                      )}
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>Muddy Awards Finalist</strong> - Create Your Own
                      (800 px)
                    </div>
                    {award && (
                      <DownloadAsset
                        url={generateLink({
                          category: award,
                          type: AssetType.POSTER
                        })}
                        type={'jpg'}
                      />
                    )}
                  </div>
                </>
              )}

            {password &&
              whichAssets === WhichAssets.WINNERS &&
              password === WINNER_PASSWORD &&
              showAssets &&
              showAssets === true && (
                <>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      <a
                        href={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_SQ.mp4`}
                        download
                      >
                        <video autoPlay={true} muted={true} loop={true}>
                          <source
                            src={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_SQ.mp4`}
                            type="video/mp4"
                          />
                        </video>
                      </a>
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>National Winner</strong> (Social Square)
                    </div>
                    <DownloadAsset
                      url={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_SQ.mp4`}
                      type={'mp4'}
                    />
                  </div>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      <a
                        href={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_Story.mp4`}
                        download
                      >
                        <video autoPlay={true} muted={true} loop={true}>
                          <source
                            src={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_Story.mp4`}
                            type="video/mp4"
                          />
                        </video>
                      </a>
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>National Winner</strong> (Story)
                    </div>
                    <DownloadAsset
                      url={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_Story.mp4`}
                      type={'mp4'}
                    />
                  </div>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      <a
                        href={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_Poster.jpg`}
                        download
                      >
                        <img
                          src={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_Poster.jpg`}
                          alt="Poster"
                        />
                      </a>
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>National Winner</strong> (Poster)
                    </div>
                    <DownloadAsset
                      url={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_Poster.jpg`}
                      type={'jpg'}
                    />
                  </div>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      <a
                        href={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_MuddyRoundel_sm.png`}
                        download
                      >
                        <img
                          src={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_MuddyRoundel_sm.png`}
                          alt="Poster"
                        />
                      </a>
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>Muddy Awards Winner</strong> - Create Your Own
                      (500px)
                    </div>
                    <DownloadAsset
                      url={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_MuddyRoundel_sm.png`}
                      type={'png'}
                    />
                  </div>
                  <div className={LeaderStyles.AssetsImage}>
                    <div className={LeaderStyles.AssetsSquare}>
                      <a
                        href={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_MuddyRoundel_lg.png`}
                        download
                      >
                        <img
                          src={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_MuddyRoundel_lg.png`}
                          alt="Poster"
                        />
                      </a>
                    </div>
                    <div className={LeaderStyles.AssetsText}>
                      <strong>Muddy Awards Winner</strong> - Create Your Own
                      (1200px)
                    </div>
                    <DownloadAsset
                      url={`https://dev.muddystilettos.co.uk/awards-2023/NationalWinners/MS_MA23_NationalWinner_MuddyRoundel_lg.png`}
                      type={'png'}
                    />
                  </div>
                </>
              )}

            {showAssets && typeof showAssets === 'string' && (
              <div>
                <p>
                  <strong>Error</strong>
                  {showAssets}
                </p>
              </div>
            )}
          </div>
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsAssets
