// extracted by mini-css-extract-plugin
export var Blocks = "NominateWidget-module--Blocks--f3943";
export var Boomshell = "NominateWidget-module--Boomshell--38371";
export var Button = "NominateWidget-module--Button--ad22c";
export var ChangeCategoryButton = "NominateWidget-module--ChangeCategoryButton--37847";
export var CheckboxWrapper = "NominateWidget-module--CheckboxWrapper--ea34a";
export var CreateUniqueLink = "NominateWidget-module--CreateUniqueLink--d7a1d";
export var Invalid = "NominateWidget-module--Invalid--4dfc2";
export var NoBottomMargin = "NominateWidget-module--NoBottomMargin--96311";
export var Nominate = "NominateWidget-module--Nominate--cbfc7";
export var Number = "NominateWidget-module--Number--5012f";
export var Padding = "NominateWidget-module--Padding--1a0db";
export var SocialShare = "NominateWidget-module--SocialShare--1362f";
export var Spacer = "NominateWidget-module--Spacer--d2ad0";
export var Step = "NominateWidget-module--Step--86c42";
export var StepThree = "NominateWidget-module--StepThree--19506";
export var Subheading = "NominateWidget-module--Subheading--7ee2c";
export var Wrapper = "NominateWidget-module--Wrapper--dea8e";
export var svgPath = "NominateWidget-module--svgPath--62c65";