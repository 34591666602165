const assetsDataCapture = async (
  firstName: string,
  secondName: string,
  companyName: string,
  businessEmail: string,
  businessCategory: string,
  region: string,
  consent: string
) => {
  const requestOptions = {
    method: 'POST', // You may want to change this to 'GET' or another method if needed
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      firstName,
      secondName,
      companyName,
      businessEmail,
      businessCategory,
      region,
      source: 'assets',
      consent
    })
  }

  // const url =
  //   'https://dev.muddystilettos.co.uk/wp-json/muddy/assets-data-capture/'
  const url = '/.netlify/functions/tideSignup'
  return await fetch(url, requestOptions)
    .then(response => {
      return response.json()
    })
    .catch(e => {
      return e.message
    })
}

export default assetsDataCapture
